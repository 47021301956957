<div class="container" fxLayout="column" fxLayoutGap="-225px" fxLayoutGap.lt-sm="0px" fxLayoutGap.lt-md="-150px">
  <div class="slider" fxShow fxHide.lt-sm>
    <div class="slider-img">
      <mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="2000" proportion="40" [useKeyboard]="true"
        [loop]="true" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" fxLayoutAlign="center center">
          <img src="{{slide.image}}" alt="" class="full-width">
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>
  <div class="slider" fxHide fxShow.lt-sm>
    <div class="slider-img">
      <mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="3000" proportion="45" [useKeyboard]="true"
        [loop]="true" [hideIndicators]="false" color="white" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides1; let i = index" [image]="slide.image"
          fxLayoutAlign="center center">
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>
  <div class="container">
    <div fxLayout="column" fxLayoutGap="25px" fxLayoutGap.lt-sm="0px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap.lt-sm="25px"
        fxLayoutGap.lt-md="5px" fxFlex="grow">
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="20">New Arrivals</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/1.jpg" alt="">
                <p class="mat-small">Helmets</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/2.jpg" alt="">
                <p class="mat-small">tyres</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/3.jpg" alt="">
                <p class="mat-small">Pressure washers</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/4.jpg" alt="">
                <p class="mat-small">protective gear</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="20">Featured Product</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/5.jpg" alt="">
                <p class="mat-small">Top realase in 2020</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/6.jpg" alt="">
                <p class="mat-small">Book in buzz in 2020</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/7.jpg" alt="">
                <p class="mat-small">Most sold book</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/8.jpg" alt="">
                <p class="mat-small">15000+ deals</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="20"> Top Selling Product/ Best Selling Product</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/home-essentials">
                <img src="assets/images/cardimage/11.jpg" alt="">
                <p class="mat-small">Home product</p>
              </div>
              <div class="cardimages" routerLink="/kitchen-dining">
                <img src="assets/images/cardimage/12.jpg" alt="">
                <p class="mat-small">Kitchen & dining</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/daily-essentials">
                <img src="assets/images/cardimage/13.jpg" alt="">
                <p class="mat-small">Daily essentials</p>
              </div>
              <div class="cardimages" routerLink="/clothing-essentials">
                <img src="assets/images/cardimage/14.jpg" alt="">
                <p class="mat-small">Clothing essentials</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpadding" fxLayout="column" fxLayoutGap="35px">
        <div class="card cardpadding slider-items" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="mat-h2">Today's Deal</p>
            <p class="color-accent" fxHide>See all deals</p>
          </div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div class="cursor" (click)="products()">
                <img [src]="slide.src">
                <div fxLayout="row" fxLayoutAlign="start end">
                  <span class="mat-h4">{{slide.text}}</span>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
        <div class="card cardpadding slider-items" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="mat-h2">Up to 60% off | Unboxed & like-new</p>
            <p class="color-accent" fxHide>See all deals</p>
          </div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide1 of slidesStore1" [id]="slide1.id">
              <div class="cursor" (click)="products()">
                <img [src]="slide1.src">
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap.lt-sm="5px"
        fxLayoutGap.lt-md="5px" fxFlex="grow" fxHide>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="15">Electronics</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/air-conditioners">
                <img src="assets/images/cardimage/1.jpg" alt="">
                <p class="mat-small">ACs</p>
              </div>
              <div class="cardimages" routerLink="/washing-machines">
                <img src="assets/images/cardimage/2.jpg" alt="">
                <p class="mat-small">Washing machines</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/home-entertainment">
                <img src="assets/images/cardimage/3.jpg" alt="">
                <p class="mat-small">Televisions</p>
              </div>
              <div class="cardimages" routerLink="/refrigerators">
                <img src="assets/images/cardimage/4.jpg" alt="">
                <p class="mat-small">Refrigerators</p>
              </div>
            </div>
            <div fxLayoutAlign="start end" fxFlex="5" fxHide>
              <a class="color-accent"> See more</a>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="15">Clothings</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/6.jpg" alt="">
                <p class="mat-small">Clothings under ₹599</p>
              </div>
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/7.jpg" alt="">
                <p class="mat-small">Footwear under ₹599</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/8.jpg" alt="">
                <p class="mat-small">Bages, watches & more under ₹599</p>
              </div>
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/9.jpg" alt="">
                <p class="mat-small">View allofferrs | up to 60% off</p>
              </div>
            </div>
            <div fxLayoutAlign="start end" fxFlex="5" fxHide>
              <a class="color-accent"> View all</a>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="15">Grocery</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/9.jpg" alt="">
                <p class="mat-small">Grocery essentials</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/10.jpg" alt="">
                <p class="mat-small">Mask & personal hygiene</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/11.jpg" alt="">
                <p class="mat-small">Household supplies & personal care</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/12.jpg" alt="">
                <p class="mat-small">Baby essentials & pet supplies</p>
              </div>
            </div>
            <div fxLayoutAlign="start end" fxFlex="5" fxHide>
              <a class="color-accent">See more</a>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpadding3">
        <div class="card1 cardpadding">
          <div fxLayout="column" fxLayoutGap="20px">
            <div [ngClass]="'cardpadding2 slider-items3 testimonial'" fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutAlign="center center">
                <p class="mat-title color-primary">Testimonial</p>
                <p class="mat-headline">Our Client Feedback</p>
              </div>
              <owl-carousel-o [options]="customOptions4">
                <ng-template carouselSlide *ngFor="let slide4 of slidesStore4" [id]="slide4.id">
                  <div class="cursor" fxFlex="grow">
                    <div class="card cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" fxFlex="grow">
                      <div fxLayout="column" class="testimonial">
                        <img src="{{slide4.src}}" alt="">
                      </div>
                      <div [ngClass.gt-md]="'cardpadding'" fxLayout="column" fxLayoutAlign="center center">
                        <p class="text-center mat-h4">Ekocart Amazing E-commerce Template, clean code, Crative & Modern
                          design.
                        </p>
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                          <p class="color-primary mat-h3">{{slide4.name}}</p>
                          <p class="mat-small text-italic">{{slide4.post}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpadding3">
        <div class="card cardpadding">
          <div fxLayout="column" fxLayoutGap="20px">
            <div [ngClass]="' slider-items3'" [ngClass.lt-sm]="'slider-item'" fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="row" fxLayoutAlign="center center">
                <p class="color-primary mat-title">Top Brands</p>
              </div>
              <owl-carousel-o [options]="customOptions2">
                <ng-template carouselSlide *ngFor="let slide2 of slidesStore2" [id]="slide2.id">
                  <div class="cursor">
                    <img [src]="slide2.src">
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-footer">
  <div class="padding-side" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap.lt-sm="20px"  fxFlex="grow">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly" fxLayoutGap.lt-sm="20px" fxFlex="grow">
        <div fxLayout="column" fxFlex="30" fxLayoutGap="10px" fxFlex.lt-sm="100">
          <p class="mat-title color-white">Quick Links</p>
          <p class="mat-h4 color-white country">Review</p>
          <p class="mat-h4 color-white country">Our Mission</p>
          <p class="mat-h4 color-white country" routerLink="/faq">FAQ</p>
          <p class="mat-h4 color-white country" routerLink="/terms-condition">Terms & Condition</p>
          <p class="mat-h4 color-white country" routerLink="/privacy-policy">Privacy Policy</p>
          <p class="mat-h4 color-white country" routerLink="/return-policy">Cancellation Policy</p>
          <p class="mat-h4 color-white country" routerLink="/return-policy">Return Policy</p>
          <p class="mat-h4 color-white country" routerLink="/return-policy">Shipping Policy</p>
        </div>
        <div fxLayout="column" fxFlex="50" fxLayoutGap="10px" fxFlex.lt-sm="100">
          <p class="mat-title color-white">About Us</p>
          <p class="mat-h4 color-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem
            Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.</p>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly" fxLayoutGap.lt-sm="20px" fxFlex="grow">
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50" fxFlex.lt-sm="100">
          <div fxLayout="column" fxLayoutGap="10px">
            <p class="mat-title color-white">Contact us</p>
            <p class="mat-h4 color-white">123 Main Street, Anytown, CA 12345 - USA.</p>
            <p class="mat-h4 color-white">Phone: +(000) 800 456 789</p>
            <p class="mat-h4 color-white">Email: info@localglobal.com</p>
          </div>
          <div fxLayout="column" fxLayoutGap="10px">
            <div>
              <p class="mat-title color-white">Get in Touch:</p>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-twitter color-primary" aria-hidden="true"></i>
              </div>
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-google-plus color-primary" aria-hidden="true"></i>
              </div>
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-facebook color-primary" aria-hidden="true"></i>
              </div>
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-youtube color-primary" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50" fxFlex.lt-sm="100">
          <p class="mat-title color-white">Newsletter</p>
          <p class="mat-h4 color-white">Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.
          </p>
          <div fxLayout="row" fxLayoutAlign="center center" fxFlex="grow">
            <mat-form-field appearance="outline" fxFlex="65" fxFlex.lt-md="50" fxFlex.lt-sm="65">
              <input matInput placeholder="youremail@example.com" class="bg-transparente">
            </mat-form-field>
            <button mat-raised-button color="primary" class="button-sub" fxFlex="35" fxFlex.lt-md="50"
              fxFlex.lt-sm="35">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" fxFlex="grow">
      <p class="color-white mat-h4">Copyright 2021 <span class="color-primary hover-color" routerLink="/home">GLOBAL
          LOCAL E-COMMERCE.</span> All Rights
        Reserved. | Powered By:- <a class="color-primary hover-color" href="https://www.techspinsolutions.com/"
          target="_blank">Techspin Solutions</a></p>
    </div>
  </div>
</div>
<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar [class.example-is-mobile]="mobileQuery.matches" class="example-toolbar" fxHide.lt-sm fxShow>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="grow">
      <div class="logo" fxLayoutAlign="center" routerLink="/home">
        <p class="mat-headline ">C Logo</p>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFlex="grow">
        <div class="sidenav-button" fxLayoutAlign="start start" fxLayout="row" fxFlex="25">
          <button class="color-hover" mat-icon-button (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="search-box" fxFlex="50">
          <div class="search-container" fxLayout="column" fxLayoutGap="10px">
            <form>
              <div class="search" fxLayout="row" fxLayoutAlign="center center">
                <input type="text" name="search">
                <button mat-raised-button type="submit" color="primary" fxHide>
                  <mat-icon>search</mat-icon>
                </button>
              </div>
            </form>
            <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.lt-md="space-around center" fxHide>
              <div class="hover-border1 color-hover">
                <p class="mat-h4 ">New Arrivals</p>
              </div>
              <div class="hover-border1 color-hover">
                <p class="mat-h4 ">Featured Product</p>
              </div>
              <div class="hover-border1 color-hover">
                <p class="mat-h4 ">Top Selling Products</p>
              </div>
              <div class="hover-border1 color-hover" routerLink='/mobile-phone' fxHide>
                <p class="mat-h4 ">Mobiles</p>
              </div>
              <div class="hover-border1 color-hover" routerLink='/todays-deal' fxHide>
                <p class="mat-h4 ">Today's Deal</p>
              </div>
              <div class="hover-border1 color-hover" fxHide.lt-sm routerLink='/fashion' fxHide>
                <p class="mat-h4 ">Fashion</p>
              </div>
              <div class="hover-border1 color-hover" routerLink='/category' fxHide>
                <p class="mat-h4 ">Category</p>
              </div>
              <div class="hover-border1 color-hover" routerLink='/new-arrival' fxHide>
                <p class="mat-h4 ">New Arrivals</p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around center" fxFlex="25">
          <div class="hover-border your-acoount" fxLayout="row" fxLayoutAlign="center center">
            <button mat-button [matMenuTriggerFor]="menu1">
              <div class="color-hover" fxLayout="row">
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="3px">
                  <p class="mat-small">{{this.yourEmail}}</p>
                  <p>Accounts & Lists</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                  <mat-icon>expand_more</mat-icon>
                </div>
              </div>
            </button>
            <mat-menu #menu1="matMenu" class="cardpadding" fxLayoutGap="10px" fxFlex>
              <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutGap="20px">
                  <div fxLayout="row" *ngIf="this.userinfo == null">
                    <button mat-raised-button class="full-width" color="primary" routerLink="/login">Sign in</button>
                  </div>
                  <div fxLayout="column">
                    <p class="mat-title">Your Account</p>
                    <p class="mat-h4 color-hover" routerLink="/your-account">Your Account</p>
                    <p class="mat-h4 color-hover" routerLink="/returns-orders">Your Orders</p>
                    <p class="mat-h4 color-hover" routerLink="/wish-list">Your Wish List</p>
                    <p class="mat-h4 color-hover">Your Seller Account</p>
                    <p class="mat-h4 color-hover" routerLink="/refer-to-friend">Refer to Friend</p>
                    <p class="mat-h4 color-hover" routerLink="/coupons" *ngIf="this.userinfo != null">Coupons</p>
                    <p class="mat-h4 color-hover" (click)="logout()" *ngIf="this.userinfo != null">Logout</p>
                  </div>
                </div>
              </div>
            </mat-menu>
          </div>
          <div class="hover-border" fxLayout="row" fxLayoutAlign="center center" fxHide.lt-md>
            <button mat-button routerLink="/returns-orders">
              <div class="color-hover" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="3px">
                <p class="mat-small">Returns</p>
                <p>& Orders</p>
              </div>
            </button>
          </div>
          <div class="hover-border" fxLayoutAlign="center center" routerLink="/add-to-cart">
            <div class="color-hover" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>add_shopping_cart</mat-icon>
              <p class="mat-h3 ">Cart</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-toolbar [class.example-is-mobile]="mobileQuery.matches" class="example-toolbar1" fxLayoutGap="10px" fxHide.gt-sm
    fxHide.lt-md fxShow.lt-sm>
    <div fxLayout="column" fxLayoutGap="5px" fxFlex="grow">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" fxFlex="grow">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="40">
          <div fxFlex="40">
            <button mat-icon-button (click)="snav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div class="logo" fxLayoutAlign="center center" fxFlex="50" routerLink="/home">
            <p class="mat-title">C Logo</p>
            <!-- <img src="assets/images/logo/logo.png" alt="" class="full-width"> -->
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="60" fxLayoutGap="20px">
          <!-- <div class="hover-border" fxFlex="40" routerLink="/login" *ngIf="this.userinfo == null">
            <div fxLayout="row" fxLayoutAlign="start start">
              <p class="mat-h3">Sign In</p>
            </div>
          </div>
          <div class="hover-border" fxFlex="78" routerLink="/login" *ngIf="this.userinfo != null">
            <div fxLayout="row" fxLayoutAlign="start start">
              <p class="mat-small">{{this.yourEmail}}</p>
            </div>
          </div> -->
          <div class="hover-border" fxFlex="60">
            <button mat-button [matMenuTriggerFor]="menu1">
              <div class="color-hover" fxLayout="row">
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="3px">
                  <p class="mat-small">{{this.yourEmail}}</p>
                  <p>Accounts & Lists</p>
                </div>
              </div>
            </button>
            <mat-menu #menu1="matMenu" class="cardpadding" fxLayoutGap="10px" fxFlex>
              <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutGap="20px">
                  <div fxLayout="row" *ngIf="this.userinfo == null">
                    <button mat-raised-button class="full-width" color="primary" routerLink="/login">Sign in</button>
                  </div>
                  <div fxLayout="column">
                    <p class="mat-title">Your Account</p>
                    <p class="mat-h4 color-hover" routerLink="/your-account">Your Account</p>
                    <p class="mat-h4 color-hover" routerLink="/returns-orders">Your Orders</p>
                    <p class="mat-h4 color-hover" routerLink="/wish-list">Your Wish List</p>
                    <p class="mat-h4 color-hover">Your Seller Account</p>
                    <p class="mat-h4 color-hover" routerLink="/refer-to-friend">Refer to Friend</p>
                    <p class="mat-h4 color-hover" routerLink="/coupons" *ngIf="this.userinfo != null">Coupons</p>
                    <p class="mat-h4 color-hover" (click)="logout()" *ngIf="this.userinfo != null">Logout</p>
                  </div>
                </div>
              </div>
            </mat-menu>
          </div>
          <div class="hover-border" fxFlex="20" routerLink="/add-to-cart">
            <div fxLayout="row" fxLayoutAlign="start start">
              <mat-icon>add_shopping_cart</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="search-box" fxFlex="48">
        <div class="search-container">
          <form>
            <div class="search" fxLayout="row" fxLayoutAlign="center center">
              <input type="text" name="search">
              <button mat-raised-button type="submit" color="primary" fxHide>
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 100 : 0">
    <mat-sidenav #snav mode="over" [fixedInViewport]="mobileQuery.matches" fixedTopGap="100">
      <mat-nav-list>
        <app-nav-list></app-nav-list>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-menu></app-menu>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
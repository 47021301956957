import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/_services/authentication.service';
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnDestroy, OnInit  {

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;
  yourEmail: any;
  userinfo = JSON.parse(localStorage.getItem('currentUser'));

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,  private router: Router,
    private authenticationService: AuthenticationService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void{
    this.yourEmail = localStorage.getItem('yourEmail')
  }

  foods: Food[] = [
    { value: 'All Categories', viewValue: 'All' },
    { value: 'Deals', viewValue: 'Deals' },
    { value: 'Alexa Skills', viewValue: 'Alexa Skills' },
    { value: 'Amazon Service', viewValue: 'Amazon Service' },
    { value: 'Amazon Fashions', viewValue: 'Amazon Fashions' },
    { value: 'Amazon Pantry', viewValue: 'Amazon Pantry' },
    { value: 'Appliance', viewValue: 'Appliance' },
    { value: 'Apps & Games', viewValue: 'Apps & Games' },
    { value: 'Baby', viewValue: 'Baby' },
    { value: 'Beauty', viewValue: 'Beauty' },
    { value: 'Books', viewValue: 'Books' },
    { value: 'Car & Motorbike', viewValue: 'Car & Motorbike' },
    { value: 'Clothing & Accessories', viewValue: 'Clothing & Accessories' },
    { value: 'Collectibles', viewValue: 'Collectibles' },
    { value: 'Computers & Accessories', viewValue: 'Computers & Accessories' },
    { value: 'Electronics', viewValue: 'Electronics' },
    { value: 'Furniture', viewValue: 'Furniture' },
    { value: 'Garden & Outdoors', viewValue: 'Garden & Outdoors' },
    { value: 'Gift Cards', viewValue: 'Gift Cards' },
    { value: 'Grocery & Gourmet Foods', viewValue: 'Grocery & Gourmet Foods' },
    { value: 'Health & Personal Care', viewValue: 'Health & Personal Care' },
    { value: 'Home & Kitchen', viewValue: 'Home & Kitchen' },
    { value: 'Industrial & Scientific', viewValue: 'Industrial & Scientific' },
    { value: 'Jewellery', viewValue: 'Jewellery' },
    { value: 'Kindle Store', viewValue: 'Kindle Store' },
    { value: 'Luggage & Bags', viewValue: 'Luggage & Bags' },
    { value: 'Luxury Beauty', viewValue: 'Luxury Beauty' },
    { value: 'Movies & TV Shows', viewValue: 'Movies & TV Shows' },
    { value: 'Music', viewValue: 'Music' },
    { value: 'Musical Instruments', viewValue: 'Musical Instruments' },
    { value: 'Office Products', viewValue: 'Office Products' },
    { value: 'Pet Supplies', viewValue: 'Pet Supplies' },
    { value: 'Prime Video', viewValue: 'Prime Video' },

  ];
  selectedFood = this.foods[0].value;

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
